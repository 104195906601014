/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { createContext, Fragment, useContext, useMemo } from 'react'
import { DeploymentPageLayout } from './DeploymentPageLayout'
import Foldable from '../../../components/Card/Foldable'
import { Flex } from '../../../components/Flex'
import theme from '../../../styles/theme'
import { useTranslation } from 'react-i18next'
import Table from '../../../components/Table'
import { useFilters, UseFiltersResult } from '../../../hooks/useFilters'
import Pagination from '../../../components/Pagination'
import {
  AppointmentCategory,
  Deployment,
  EmployeeDeployment,
  EmployeeDeploymentStatus,
} from '../../../__generated__/graphql'
import { useDeploymentEmployees } from './hooks/useDeploymentEmployees'
import { useCurrentDeploymentId } from './hooks/useCurrentDeploymentId'
import {
  usePagination,
  UsePaginationResult,
} from '../../../hooks/usePagination'
import { CellProps, Column, Row } from 'react-table'
import {
  UseOrderByValue,
  useLocalOrderBy,
  useOrderBy,
  UseOrderByResult,
} from '../../../hooks/useOrderBy'
import Icon from '../../../components/Icon'

import { EmployeeOptionsIcons } from './components/EmployeeOptionsIcons'
import { EmployeeAppointmentDateOrAbsent } from './components/EmployeeAppointmentDateOrAbsent'
import { differenceInDays } from 'date-fns'
import { DeleteDeploymentEmployeeButton } from './components/DeleteDeploymentEmployeeButton'
import { VerifyEmployeeEmailButton } from './components/VerifyEmployeeEmailButton'
import { EditEmployeeProfileButton } from './components/EditEmployeeProfileButton'
import { ToggleEmployeeDeploymentStatusButton } from './components/ToggleEmployeeDeploymentStatusButton'
import { EditEmployeeAppointmentButton } from './components/EditEmployeeAppointmentButton'
import { DeselectEmployeeButton } from './components/DeselectEmployeeButton'
import InputSelect from '../../../components/InputSelect'
import { SelectVariant } from '../../../hooks/useVariant/variants/select'
import Input from '../../../components/Input'
import { TextVariant } from '../../../hooks/useVariant/variants/text'
import { useDistinctBy } from '../../../hooks/useDistinctBy'
import { useLocalFilters } from '../../../hooks/useLocalFilters'
import { TotalDistanceInterval } from './const/TotalDistanceInterval'
import { LastRideTime } from './const/LastRideTime'
import { ReturnPackageButton } from './components/ReturnPackageButton'
import { PickupBikeButton } from './components/PickupBikeButton'
import { employeeNameFormatter } from '../../../utils/formatter'
import { CompanySelectedEmployeesTableRow } from '../../../types/AnimatorCompanyEmployees'
import EmployeeDocumentCell from '../../../components/Table/CustomCells/EmployeeDocumentCell'
import EmployeeDocumentsModal from '../Company/Employees/SelectedEmployees/DocumentsModal/'
import DownloadCSVButton from '../../../components/Button/DownloadCSVButton'
import { formatEmployeesExportV3 } from '../../../utils/gql/employeesExportV3'
import SendMailButton from '../../../components/Button/SendMail'
import { EditEmployeeHabitsButton } from './components/EditEmployeeHabitsButton'
import { ReleaseDepositButton } from './components/ReleaseDepositButton'
import { RetentionOnDepositButton } from './components/RetentionOnDepositButton'
import { GenerateRemissionOrderButton } from './components/GenerateRemissionOrderButton'

export const AnimatorDeploymentUsers: React.FC = () => {
  const { t } = useTranslation()

  return (
    <DeploymentPageLayout>
      <TableCard
        status={EmployeeDeploymentStatus.Beneficiary}
        color={theme.colors.greenLegend}
        title={t('animator.deployments.users.beneficiaryTable.title')}
      >
        <BeneficiaryTable />
      </TableCard>
      <TableCard
        status={EmployeeDeploymentStatus.Selected}
        color={theme.colors.orangeLegend}
        title={t('animator.deployments.users.selectedTable.title')}
      >
        <SelectedTable />
      </TableCard>
      <TableCard
        color={theme.colors.gray4}
        title={t('animator.deployments.users.candidatesTable.title')}
      >
        <CandidatesTable />
      </TableCard>
    </DeploymentPageLayout>
  )
}

const BeneficiaryTable: React.FC = () => {
  const { t } = useTranslation()
  const {
    filters: { register },
    orderBy: { getTableProps },
    data,
    deployment,
  } = useTableCardContext()

  const [documentsModal, setDocumentsModal] = React.useState<string | null>(
    null,
  )

  const DocumentCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => (
      <EmployeeDocumentCell
        setModal={employeeId => setDocumentsModal(employeeId)}
        row={row}
        deploymentType={deployment?.deploymentType}
      />
    ),
    [deployment],
  )

  return (
    <Fragment>
      <Flex direction="row" gap="16px" align="end">
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.beneficiaryTable.timeSpan')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            {
              value: LastRideTime.YESTERDAY,
              label: t(
                `animator.deployments.users.beneficiaryTable.lastRideTime.${LastRideTime.YESTERDAY}`,
              ),
            },
            {
              value: LastRideTime.LAST_WEEK,
              label: t(
                `animator.deployments.users.beneficiaryTable.lastRideTime.${LastRideTime.LAST_WEEK}`,
              ),
            },
          ]}
          {...register('lastRideTime')}
        />
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.beneficiaryTable.totalDistance')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            {
              value: TotalDistanceInterval.LT_1,
              label: t(
                `animator.deployments.users.beneficiaryTable.totalDistanceInterval.${TotalDistanceInterval.LT_1}`,
              ),
            },
            {
              value: TotalDistanceInterval.BT_1_10,
              label: t(
                `animator.deployments.users.beneficiaryTable.totalDistanceInterval.${TotalDistanceInterval.BT_1_10}`,
              ),
            },
            {
              value: TotalDistanceInterval.BT_10_50,
              label: t(
                `animator.deployments.users.beneficiaryTable.totalDistanceInterval.${TotalDistanceInterval.BT_10_50}`,
              ),
            },
            {
              value: TotalDistanceInterval.GT_50,
              label: t(
                `animator.deployments.users.beneficiaryTable.totalDistanceInterval.${TotalDistanceInterval.GT_50}`,
              ),
            },
          ]}
          {...register('totalDistance')}
        />
        <Input
          withError={false}
          icon="magnifier"
          variant={TextVariant.white}
          {...register('search')}
        />
      </Flex>
      <Table
        {...getTableProps()}
        columns={[
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.name',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) =>
              employeeNameFormatter(
                original.employee.firstName,
                original.employee.lastName,
              ),
            accessor: 'employee.firstName',
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.docs',
            ) as string,
            Cell: DocumentCell,
            accessor: 'docs',
            disableSortBy: true,
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.bike',
            ) as string,
            Cell: ({ value }) => value ?? 'Inconnu',
            accessor: 'bikeLoan.bike.stickerId',
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.totalDistance',
            ) as string,
            Cell: ({ value }) => `${value.toFixed(2)} km`,
            accessor: 'totalDistance',
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.dailyDistance',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => {
              if (deployment == null) return '0 km'

              const dailyDistance = original.employee?.dailyDistance
              if (!dailyDistance) return '0 km'
              return `${dailyDistance.toFixed(2)} km`
            },
            accessor: 'dailyDistance',
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.end',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <EmployeeAppointmentDateOrAbsent
                employeeDeployment={original}
                category={AppointmentCategory.LoanReturn}
              />
            ),
            accessor: 'bikeLoan.loanEndDate',
          },
          {
            Header: t(
              'animator.deployments.users.beneficiaryTable.actions',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <Flex direction="row" justify="center" gap="8px">
                {original.bikeLoan && (
                  <ReturnPackageButton employeeDeployment={original} />
                )}
                {original.remissionOrderUrl ? (
                  <a
                    href={original.remissionOrderUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="remissionOrder" />
                  </a>
                ) : (
                  <GenerateRemissionOrderButton
                    employeeId={original.employee.id}
                    bikeLoanId={original.bikeLoan?.id ?? ''}
                  />
                )}
                {!original.bikeLoan?.bikeReturned && (
                  <EditEmployeeAppointmentButton
                    employeeDeployment={original}
                  />
                )}
                {(deployment?.programStep.stepNbr ?? 0) < 5 &&
                  original.bikeLoan?.bikeReturned &&
                  !original.depositReleasedAt && (
                    <RetentionOnDepositButton employeeDeployment={original} />
                  )}

                <EditEmployeeProfileButton employee={original.employee} />
              </Flex>
            ),
            id: 'actions',
            disableSortBy: true,
            cellStyle: () => ({ overflow: 'visible' }),
          } as Column,
        ]}
        data={data}
      />
      {documentsModal && (
        <EmployeeDocumentsModal
          isOpen={!!documentsModal}
          closeModal={() => setDocumentsModal(null)}
          employeeId={documentsModal}
          deploymentType={deployment?.deploymentType}
          refetchTable={() => {}}
        />
      )}
    </Fragment>
  )
}

const SelectedTable: React.FC = () => {
  const { t } = useTranslation()
  const {
    filters: { register },
    orderBy: { getTableProps },
    data,
    deployment,
  } = useTableCardContext()

  const options = useDistinctBy(data, 'option')

  const [documentsModal, setDocumentsModal] = React.useState<string | null>(
    null,
  )

  const DocumentCell = React.useCallback(
    ({ row }: { row: Row<CompanySelectedEmployeesTableRow> }) => (
      <EmployeeDocumentCell
        setModal={employeeId => setDocumentsModal(employeeId)}
        row={row}
        deploymentType={deployment?.deploymentType}
      />
    ),
    [deployment],
  )

  return (
    <Fragment>
      <Flex direction="row" gap="16px" align="end">
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.selectedTable.state')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            {
              value: 'true',
              label: t('shared.complete'),
            },
            {
              value: 'false',
              label: t('shared.incomplete'),
            },
          ]}
          {...register('isComplete')}
        />
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.selectedTable.childSeat')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            ...options.map(value => ({
              value,
              label: t(`shared.babySeatOptions.${value}`),
            })),
          ]}
          {...register('babySeatOption')}
        />
        <Input
          withError={false}
          icon="magnifier"
          variant={TextVariant.white}
          {...register('search')}
        />
      </Flex>
      <Table
        {...getTableProps()}
        columns={[
          {
            Header: t(
              'animator.deployments.users.selectedTable.name',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) =>
              employeeNameFormatter(
                original.employee.firstName,
                original.employee.lastName,
              ),
            accessor: 'employee.firstName',
          },
          {
            Header: t(
              'animator.deployments.users.selectedTable.docs',
            ) as string,
            Cell: DocumentCell,
            accessor: 'docs',
            disableSortBy: true,
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.size',
            ) as string,
            Cell: ({ value }) => `${value} cm`,
            accessor: 'employee.height',
          },
          {
            Header: t(
              'animator.deployments.users.selectedTable.options',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <EmployeeOptionsIcons employeeDeployment={original} />
            ),
            accessor: 'options',
            cellStyle: () => ({ overflow: 'inherit' }),
          } as Column,
          {
            Header: t(
              'animator.deployments.users.selectedTable.start',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <EmployeeAppointmentDateOrAbsent
                employeeDeployment={original}
                category={AppointmentCategory.LoanDelivery}
              />
            ),
            accessor: 'bikeLoan.loanDate',
          },
          {
            Header: t(
              'animator.deployments.users.selectedTable.actions',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <Flex direction="row" justify="center" gap="8px">
                {(deployment?.programStep.stepNbr ?? 0) > 1 && (
                  <DeselectEmployeeButton employeeDeploymentId={original.id} />
                )}
                {(deployment?.programStep.stepNbr ?? 0) > 2 && (
                  <Fragment>
                    <PickupBikeButton
                      deployment={deployment}
                      employee={original.employee}
                      employeeDeploymentId={original.id}
                    />
                    <EditEmployeeAppointmentButton
                      employeeDeployment={original}
                    />
                  </Fragment>
                )}

                <EditEmployeeProfileButton employee={original.employee} />
              </Flex>
            ),
            id: 'actions',
            disableSortBy: true,
          },
        ]}
        data={data}
      />
      {documentsModal && (
        <EmployeeDocumentsModal
          isOpen={!!documentsModal}
          closeModal={() => setDocumentsModal(null)}
          employeeId={documentsModal}
          deploymentType={deployment?.deploymentType}
          refetchTable={() => {}}
        />
      )}
    </Fragment>
  )
}

const CandidatesTable: React.FC = () => {
  const { t } = useTranslation()
  const {
    filters: { register },
    orderBy: { getTableProps },
    data,
    deployment,
  } = useTableCardContext()

  const travelModes = useDistinctBy<string>(data, 'employee.travelMode.name')
  const parkingTypes = useDistinctBy<string>(
    data,
    'employee.bikeParkingType.name',
  )

  const beneficiaryAndSelectedCount =
    deployment?.employeeDeployments?.filter(
      x =>
        x.status === EmployeeDeploymentStatus.Selected ||
        x.status === EmployeeDeploymentStatus.Beneficiary,
    ).length ?? 0

  const capacityReached =
    beneficiaryAndSelectedCount >= deployment?.loanQuantityBikes!

  return (
    <Fragment>
      <Flex direction="row" gap="16px" align="end">
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.candidatesTable.mode')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            ...travelModes.map(value => ({
              value,
              label: t(`shared.travelModeLabel.${value}`),
            })),
          ]}
          {...register('travelMode')}
        />
        <InputSelect
          withError={false}
          variant={SelectVariant.white}
          label={t('animator.deployments.users.candidatesTable.parking')}
          defaultValue="all"
          options={[
            {
              value: 'all',
              label: t('animator.deployments.users.options.all'),
            },
            ...parkingTypes.map(value => ({
              value,
              label: t(`shared.parkingType.${value}`),
            })),
          ]}
          {...register('parkingType')}
        />
        <Input
          withError={false}
          icon="magnifier"
          variant={TextVariant.white}
          {...register('search')}
        />
      </Flex>
      <Table
        {...getTableProps()}
        columns={[
          {
            Header: t(
              'animator.deployments.users.candidatesTable.name',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) =>
              employeeNameFormatter(
                original.employee.firstName,
                original.employee.lastName,
              ),
            accessor: 'employee.firstName',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.dt',
            ) as string,
            Cell: ({ value }) => `${value ?? 0} km`,
            accessor: 'employee.workTravelDistance',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.size',
            ) as string,
            Cell: ({ value }) => `${value ?? 0} cm`,
            accessor: 'employee.height',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.mode',
            ) as string,
            Cell: ({ value }) =>
              value ? t(`shared.travelModeLabel.${value}`) : t('shared.none'),
            accessor: 'employee.travelMode.name',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.options',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => (
              <EmployeeOptionsIcons employeeDeployment={original} />
            ),
            accessor: 'option',
            cellStyle: () => ({ overflow: 'inherit' }),
          } as Column,
          {
            Header: t(
              'animator.deployments.users.candidatesTable.profile',
            ) as string,
            Cell: ({ value }) => (
              <Flex justify="center">
                <Icon
                  color={value ? theme.colors.success : theme.colors.redLegend}
                  type={value ? 'checkbox' : 'blockDoubleCrossed'}
                />
              </Flex>
            ),
            accessor: 'employee.profileCompleted',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.order',
            ) as string,
            accessor: 'employee.order',
          },
          {
            Header: t(
              'animator.deployments.users.candidatesTable.actions',
            ) as string,
            Cell: ({ row: { original } }: CellProps<EmployeeDeployment>) => {
              return (
                <Flex direction="row" justify="center" gap="8px">
                  {(deployment?.programStep.stepNbr ?? 0) > 1 && (
                    <ToggleEmployeeDeploymentStatusButton
                      employeeDeployment={original}
                      disabled={
                        (deployment?.programStep.stepNbr ?? 0) > 4 ||
                        capacityReached ||
                        original.status === EmployeeDeploymentStatus.Beneficiary
                      }
                    />
                  )}
                  <EditEmployeeProfileButton employee={original.employee} />
                  <EditEmployeeHabitsButton employee={original.employee} />
                  {!original.employee.user.emailVerified && (
                    <VerifyEmployeeEmailButton employee={original.employee} />
                  )}
                  {original.status === EmployeeDeploymentStatus.Candidate && (
                    <DeleteDeploymentEmployeeButton
                      deploymentEmployeeId={original.id}
                    />
                  )}
                </Flex>
              )
            },
            accessor: 'actions',
            disableSortBy: true,
          },
        ]}
        data={data}
      />
    </Fragment>
  )
}

const lastRideTimeDifferences = {
  [LastRideTime.YESTERDAY]: 1,
  [LastRideTime.LAST_WEEK]: 7,
}

const totalDistanceIntervals: Record<
  TotalDistanceInterval,
  { min?: number; max?: number }
> = {
  [TotalDistanceInterval.LT_1]: { max: 1 },
  [TotalDistanceInterval.BT_1_10]: { min: 1, max: 10 },
  [TotalDistanceInterval.BT_10_50]: { min: 10, max: 50 },
  [TotalDistanceInterval.GT_50]: { min: 50 },
}

interface TableCardContextFilters {
  lastRideTime: LastRideTime
  totalDistance: TotalDistanceInterval
  travelMode: string
  parkingType: string
  isComplete: string
  babySeatOption: string
  search: string
}

interface TableCardContextValue {
  filters: UseFiltersResult<TableCardContextFilters>
  orderBy: UseOrderByResult
  pagination: UsePaginationResult
  data: EmployeeDeployment[]
  deployment?: Deployment
}

const TableCardContext = createContext<TableCardContextValue | null>(null)

const useTableCardContext = () =>
  useContext(TableCardContext) as TableCardContextValue

interface TableCardProps {
  status?: EmployeeDeploymentStatus
  color: string
  title: string
  count?: {
    current: number
    max: number
  }
}

const TableCard: React.FC<TableCardProps> = ({
  color,
  title,
  status,
  children,
}) => {
  const deploymentId = useCurrentDeploymentId()
  const { data } = useDeploymentEmployees(deploymentId)
  const filtersResult = useFilters<TableCardContextFilters>()

  const defaultSort: UseOrderByValue = {
    columnName: 'employee.order',
    sort: 'asc',
  }

  const orderBy = useOrderBy({ defaultValue: defaultSort })
  const pagination = usePagination({ take: 10 })

  const byStatus = useMemo(() => {
    if (status == null) return data?.deployment?.employeeDeployments
    return data?.deployment?.employeeDeployments.filter(
      d => d.status === status,
    )
  }, [data, status])

  const { filters } = filtersResult
  const filtered = useLocalFilters(byStatus, {
    option: { value: filters.babySeatOption },
    'employee.travelMode.name': { value: filters.travelMode },
    'employee.bikeParkingType.name': { value: filters.parkingType },
    totalDistance: {
      value: filters.totalDistance,
      apply: (item, filter: TotalDistanceInterval) => {
        const interval = totalDistanceIntervals[filter]
        if (interval.min != null && item.totalDistance < interval.min)
          return false
        if (interval.max != null && item.totalDistance >= interval.max)
          return false
        return true
      },
    },
    lastRideDate: {
      value: filters.lastRideTime,
      apply: (item, filter: LastRideTime) => {
        if (item.lastRideDate == null) return false
        const diff = differenceInDays(Date.now(), new Date(item.lastRideDate))
        return diff <= lastRideTimeDifferences[filter]
      },
    },
    isComplete: {
      value: filters.isComplete,
      apply: (item, value: string) => {
        const isComplete =
          item.employee.profileCompleted &&
          !!item.employee.identityCardVerified &&
          item.employee.depositId != null
        return isComplete === (value === 'true')
      },
    },
    search: {
      value: filters.search,
      apply: (item, value: string) => {
        const lower = value.toLowerCase()
        return (
          item.employee.firstName.toLowerCase().includes(lower) ||
          item.employee.lastName.toLowerCase().includes(lower) ||
          item.employee.user.email.toLowerCase().includes(lower) ||
          (item.bikeLoan?.bike?.stickerId != null &&
            item.bikeLoan.bike.stickerId.toString().includes(value))
        )
      },
    },
  })

  const ordered = useLocalOrderBy(orderBy.orderBy, filtered)

  const sliced = useMemo(() => {
    return ordered?.slice(pagination.page * 10, pagination.page * 10 + 10)
  }, [ordered, pagination.page])

  const employeeAvailableForRelease = byStatus
    ?.filter(x => x?.employee?.depositId !== null)
    .filter(x => x?.bikeLoan?.bikeReturned)
    .filter(x => !x?.employee?.depositReleasedAt)

  const showReleaseDepositButton = employeeAvailableForRelease?.length !== 0

  const beneficiaryCount =
    data?.deployment?.employeeDeployments?.filter(
      x => x.status === EmployeeDeploymentStatus.Beneficiary,
    ).length ?? 0

  const capacityRest = data?.deployment?.loanQuantityBikes! - beneficiaryCount

  return (
    <Foldable
      alignEnd
      isDefaultFolded={false}
      title={
        <Flex direction="row" align="center" gap="16px">
          <span
            css={{
              height: '21px',
              width: '21px',
              borderRadius: '100%',
              backgroundColor: color,
            }}
          />
          <h3
            css={{
              fontWeight: 500,
              fontSize: '18px',
              color: theme.colors.gray1,
            }}
          >
            {title}
          </h3>
          {status != null && data && filtered && (
            <span
              css={{
                fontWeight: 700,
                fontSize: '14px',
                color: 'white',
                backgroundColor: color,
                borderRadius: '4px',
                padding: '4px 6px',
              }}
            >
              {filtered.length} sur{' '}
              {status === EmployeeDeploymentStatus.Beneficiary &&
                data.deployment!.loanQuantityBikes}
              {status === EmployeeDeploymentStatus.Selected && capacityRest}
            </span>
          )}
        </Flex>
      }
    >
      <Flex direction="column" gap="16px" css={{ marginTop: '16px' }}>
        <TableCardContext.Provider
          value={{
            filters: filtersResult,
            orderBy,
            pagination,
            data: (sliced ?? []) as EmployeeDeployment[],
            deployment: data?.deployment as Deployment,
          }}
        >
          {children}
        </TableCardContext.Provider>
        <div css={{ alignSelf: 'center' }}>
          <Pagination
            {...pagination.getPaginationProps({
              totalData: filtered?.length,
            })}
          />
        </div>
        {ordered && (
          <Flex
            direction="row"
            css={{
              position: 'absolute',
              right: 24,
              bottom: 20,
            }}
          >
            {status === EmployeeDeploymentStatus.Beneficiary &&
              showReleaseDepositButton && (
                <ReleaseDepositButton employeeDeployment={byStatus} />
              )}

            <DownloadCSVButton
              filename={`${title.replace(/\s+/g, '-').toLowerCase()}.csv`}
              data={formatEmployeesExportV3({
                employees: ordered,
              })}
            />
            <SendMailButton
              mailTo={ordered.map(item => item.employee.user.email)}
            />
          </Flex>
        )}
      </Flex>
    </Foldable>
  )
}

export default AnimatorDeploymentUsers
