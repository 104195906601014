import { gql } from '@apollo/client'

export const MUTATION_UPDATE_TERRITORY = gql`
  mutation UpdateTerritory(
    $where: TerritoryDepartmentWhereUniqueInput!
    $data: TerritoryDepartmentUpdateInput!
  ) {
    updateOneTerritoryDepartment(where: $where, data: $data) {
      name
    }
  }
`

export const MUTATION_UPDATE_ANIMATOR = gql`
  mutation UpdateAnimator(
    $where: AnimatorWhereUniqueInput!
    $data: AnimatorUpdateInput!
  ) {
    updateOneAnimator(where: $where, data: $data) {
      id
    }
  }
`
export const MUTATION_CREATE_SITE = gql`
  mutation CreateSite($site: SiteCreateInput!) {
    createSite(site: $site) {
      id
    }
  }
`
export const MUTATION_UPDATE_SITE = gql`
  mutation UpdateSite($data: SiteUpdateInput!) {
    updateSite(data: $data) {
      id
    }
  }
`

export const MUTATION_CREATE_ANIMATOR = gql`
  mutation CreateAnimator($data: AnimatorCreateInput!) {
    createOneAnimator(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_AREA = gql`
  mutation UpdateArea($where: AreaWhereUniqueInput!, $data: AreaUpdateInput!) {
    updateOneArea(where: $where, data: $data) {
      id
    }
  }
`

export const MUTATION_CREATE_AREA = gql`
  mutation CreateArea($data: AreaCreateInput!) {
    createOneArea(data: $data) {
      id
    }
  }
`

export const MUTATION_DELETE_AREA = gql`
  mutation DeleteArea($areaId: Int!, $newAreaId: Int!) {
    deleteArea(areaId: $areaId, newAreaId: $newAreaId)
  }
`

export const MUTATION_DELETE_ANIMATOR = gql`
  mutation DeleteAnimator($id: String!) {
    deleteAnimator(id: $id)
  }
`

export const MUTATION_DELETE_COMPANY = gql`
  mutation DeleteCompany($id: String!) {
    deleteCompany(id: $id)
  }
`

export const MUTATION_ARCHIVE_COMPANY = gql`
  mutation ArchiveCompany($id: String!) {
    archiveCompany(id: $id)
  }
`

export const MUTATION_UNARCHIVE_COMPANY = gql`
  mutation UnArchiveCompany($id: String!) {
    unArchiveCompany(id: $id)
  }
`

export const MUTATION_DELETE_EMPLOYEE = gql`
  mutation DeleteEmployee($employeeId: String!) {
    deleteEmployee(employeeId: $employeeId)
  }
`

export const MUTATION_UPDATE_EMPLOYEE_APPOINTMENT = gql`
  mutation UpdateEmployeeAppointment(
    $employeeId: String!
    $deploymentId: String!
    $data: EmployeeUpdateAppointmentInput!
  ) {
    updateEmployeeAppointment(
      employeeId: $employeeId
      deploymentId: $deploymentId
      data: $data
    )
  }
`

export const MUTATION_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        name
        companyId
        companyName
        companyCode
        nbrConnections
        userRole {
          name
        }
        companyProgramStep {
          stepNbr
          name
        }
      }
    }
  }
`

export const MUTATION_VALID_BIKE_LOAN = gql`
  mutation AcceptBikeLoan(
    $employeeId: String!
    $employeeDeploymentId: String!
  ) {
    acceptBikeLoan(
      employeeId: $employeeId
      employeeDeploymentId: $employeeDeploymentId
    ) {
      id
    }
  }
`

export const MUTATION_CONFIRM_USER_EMAIL = gql`
  mutation ConfirmEmail($userId: ID!, $email: String!) {
    confirmEmail(userId: $userId, email: $email)
  }
`

export const MUTATION_UPLOAD_AVATAR = gql`
  mutation UploadAvatar($file: Upload!, $id: String, $role: role) {
    uploadAvatar(file: $file, id: $id, role: $role) {
      avatarUrl
    }
  }
`

export const MUTATION_UPDATE_ONE_COMPANY = gql`
  mutation UpdateOneCompany(
    $where: CompanyWhereUniqueInput!
    $data: CompanyUpdateInput!
  ) {
    updateOneCompany(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_UPDATE_ONE_BIKE_COMPONENT = gql`
  mutation UpdateBikeComponent(
    $data: BikeComponentUpdateInput!
    $where: BikeComponentWhereUniqueInput!
  ) {
    updateOneBikeComponent(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_UPDATE_ONE_LOAN_PACKAGE = gql`
  mutation UpdateLoanPackage(
    $data: LoanPackageUpdateInput!
    $where: LoanPackageWhereUniqueInput!
  ) {
    updateOneLoanPackage(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_EDIT_PASSWORD = gql`
  mutation EditPassword($oldPassword: String!, $newPassword: String!) {
    editPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`

export const MUTATION_CREATE_RECLAIM_ON_DEPOSIT = gql`
  mutation CreateReclaimOnDeposit(
    $amount: Float!
    $employeeId: String!
    $reason: String!
    $employeeDeploymentId: String!
  ) {
    createReclaimOnDeposit(
      amount: $amount
      employeeId: $employeeId
      reason: $reason
      employeeDeploymentId: $employeeDeploymentId
    )
  }
`

export const MUTATION_RELEASE_DEPOSITS = gql`
  mutation ReleaseDeposits($employeeDeploymentIds: [ID!]!) {
    releaseDeposits(employeeDeploymentIds: $employeeDeploymentIds) {
      employeeDeploymentId
      canceledDeposit
    }
  }
`

export const MUTATION_UPDATE_ONE_EMPLOYEE = gql`
  mutation UpdateOneEmployee(
    $where: EmployeeWhereUniqueInput!
    $data: EmployeeUpdateInput!
  ) {
    updateOneEmployee(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_UPDATE_ONE_ADMIN = gql`
  mutation UpdateOneAdministrator(
    $where: AdministratorWhereUniqueInput!
    $data: AdministratorUpdateInput!
  ) {
    updateOneAdministrator(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_CREATE_EMPLOYEE_FROM_QUIZZ = gql`
  mutation CreateEmployeeFromQuizz($inputs: CreateEmployeeFromQuizzInputs!) {
    createEmployeeFromQuizz(inputs: $inputs) {
      id
    }
  }
`

export const MUTATION_CHECK_EMAIL = gql`
  mutation CheckEmail($email: String!) {
    verifyUserEmail(email: $email)
  }
`

/** EDIT BIKE MODAL */

export const MUTATION_UPDATE_BIKE_INFO = gql`
  mutation UpdateBikeInfo(
    $where: BikeWhereUniqueInput!
    $data: BikeUpdateInput!
  ) {
    updateOneBike(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_DECLAIR_BIKE_IN_REPAIR = gql`
  mutation DeclairBikeInRepair(
    $bikeId: String!
    $repairerLocation: String!
    $resolvedDate: DateTime!
    $note: String
  ) {
    depositBikeRepair(
      bikeId: $bikeId
      repairerLocation: $repairerLocation
      resolvedDate: $resolvedDate
      note: $note
    )
  }
`

export const MUTATION_UDPATE_CURRENT_BIKE_REPAIR = gql`
  mutation UpdateBikeCurrentRepair(
    $repairId: String!
    $repairerLocation: String
    $resolvedDate: DateTime
    $note: String
  ) {
    updateCurrentRepair(
      repairId: $repairId
      repairerLocation: $repairerLocation
      resolvedDate: $resolvedDate
      note: $note
    )
  }
`

export const MUTATION_RECOVER_BIKE_FROM_REPAIR = gql`
  mutation RecoverBikeFromRepair(
    $bikeId: String!
    $price: Float!
    $note: String
    $newBikeCondition: String!
  ) {
    recoverBikeRepair(
      bikeId: $bikeId
      price: $price
      note: $note
      newBikeCondition: $newBikeCondition
    )
  }
`

export const MUTATION_SET_BIKE_STOLEN = gql`
  mutation SetBikeStolen($isStolen: Boolean!) {
    setBikeStolen(isStolen: $isStolen)
  }
`

export const MUTATION_ANIMATOR_SET_BIKE_STOLEN = gql`
  mutation AnimatorSetBikeStolen($isStolen: Boolean!, $bikeId: String!) {
    animatorSetBikeStolen(isStolen: $isStolen, bikeId: $bikeId)
  }
`

export const MUTATION_CREATE_ISSUE_WITH_BIKE = gql`
  mutation CreateIssueWithBike($comment: String!) {
    createIssueWithBike(comment: $comment)
  }
`

export const MUTATION_CREATE_APPOINTMENTS = gql`
  mutation CreateAppointmentsWithInput(
    $input: CompanyAppointmentsCreationInput
  ) {
    createAppointments(input: $input) {
      id
    }
  }
`
export const MUTATION_ADD_ABSENCE_EMPLOYEE = gql`
  mutation AddAbsenceEmployee($employeeId: String!, $appointmentId: String!) {
    addAbsenceEmployee(employeeId: $employeeId, appointmentId: $appointmentId)
  }
`

export const MUTATION_VERIFY_PASSWORD = gql`
  mutation VerifyPassword($password: String!) {
    verifyPassword(password: $password)
  }
`

export const MUTATION_CREATE_FLEET = gql`
  mutation CreateOneFleet($data: FleetCreateInput!) {
    createOneFleet(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_FLEET = gql`
  mutation UpdateOneFleet(
    $data: FleetUpdateInput!
    $where: FleetWhereUniqueInput!
  ) {
    updateOneFleet(data: $data, where: $where) {
      id
    }
  }
`

export const MUTATION_CHANGE_FLEET = gql`
  mutation ChangeFleet($lastFleetId: String!, $newFleetId: String!) {
    changeFleet(lastFleetId: $lastFleetId, newFleetId: $newFleetId)
  }
`

export const MUTATION_DELETE_ONE_FLEET = gql`
  mutation DeleteOneFleet($where: FleetWhereUniqueInput!) {
    deleteOneFleet(where: $where) {
      id
    }
  }
`

export const MUTATION_CREATE_ONE_BIKE = gql`
  mutation CreateOneBike($data: BikeCreateInput!) {
    createOneBike(data: $data) {
      id
    }
  }
`

// export const MUTATION_CREATE_POST = gql`
//   mutation CreatePost($title: String!, $MIME: String!, $content: String!) {
//     createPost(input:{title: $title, MIME: $MIME, content: $content})
//   }
// `;

// export const MUTATION_UPDATE_POST = gql`
//   mutation UpdatePost($id: ID!, $title: String!, $MIME: String!, $content: String!) {
//     updatePost(input:{id: $id, title: $title, MIME: $MIME, content: $content}) {
//       id
//     }
//   }
// `;

export const MUTATION_ADD_DEMONSTRATION_TO_BIKE = gql`
  mutation AddDemonstrationToBike(
    $bikeId: String!
    $data: addDemonstrationToBikeData!
  ) {
    addDemonstrationToBike(bikeId: $bikeId, data: $data) {
      id
    }
  }
`

export const MUTATION_DELETE_DEMONSTRATION_TO_BIKE = gql`
  mutation DeleteDemonstrationToBike($bikeId: String!) {
    deleteDemonstrationToBike(bikeId: $bikeId) {
      id
    }
  }
`

export const MUTATION_ADD_SOLD_TO_BIKE = gql`
  mutation AddSoldToBike($bikeId: String!, $data: addSoldToBikeData!) {
    addSoldToBike(bikeId: $bikeId, data: $data) {
      id
    }
  }
`

export const MUTATION_DELETE_SOLD_TO_BIKE = gql`
  mutation DeleteSoldToBike($bikeId: String!) {
    deleteSoldToBike(bikeId: $bikeId) {
      id
    }
  }
`

export const MUTATION_CREATE_DEPLOYMENT = gql`
  mutation CreateDeployment($data: DeploymentCreationInput!) {
    createDeployment(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_DEPLOYMENT = gql`
  mutation UpdateDeployment($data: DeploymentUpdateInput!) {
    updateDeployment(data: $data) {
      id
    }
  }
`

export const MUTATION_DELETE_DEPLOYMENT = gql`
  mutation DeleteDeployment($data: DeploymentDeletionInput!) {
    deleteDeployment(data: $data)
  }
`

export const MUTATION_DEPLOYMENT_NEXT_STEP = gql`
  mutation DeploymentNextStep($data: DeploymentNextStepInput!) {
    deploymentNextStep(data: $data)
  }
`

export const MUTATION_ARCHIVE_DEPLOYMENT = gql`
  mutation ArchiveDeployment($data: ArchiveDeploymentInput!) {
    archiveDeployment(data: $data)
  }
`

export const MUTATION_UNARCHIVE_DEPLOYMENT = gql`
  mutation UnarchiveDeployment($data: ArchiveDeploymentInput!) {
    unarchiveDeployment(data: $data)
  }
`

export const MUTATION_DEPLOYMENT_NAME_IS_UNIQUE = gql`
  mutation DeploymentNameIsUnique($id: String, $name: String!) {
    deploymentNameIsUnique(id: $id, name: $name)
  }
`

export const MUTATION_UDPDATE_COMPANY_REFERRER = gql`
  mutation UpdateCompanyReferrer($data: ReferrerUpdateInput!) {
    updateCompanyReferrer(data: $data) {
      id
    }
  }
`

export const MUTATION_CREATE_COMPANY_REFERRER = gql`
  mutation createCompanyReferrer($data: ReferrerCreateInput!) {
    createCompanyReferrer(data: $data) {
      id
    }
  }
`

export const MUTATION_DELETE_COMPANY_REFERRER = gql`
  mutation deleteCompanyReferrer($deleteCompanyReferrerId: String!) {
    deleteCompanyReferrer(id: $deleteCompanyReferrerId) {
      id
    }
  }
`

export const MUTATION_DELETE_COMPANY_SITE = gql`
  mutation deleteCompanySite(
    $deleteSiteId: String!
    $replacementSiteId: String!
  ) {
    deleteSite(id: $deleteSiteId, replacementSiteId: $replacementSiteId) {
      id
    }
  }
`

export const MUTATION_ADMIN_TRANSFER_SITE_TO_COMPANY = gql`
  mutation AdminTransferSiteToCompany($siteId: String!, $companyId: String!) {
    transferSiteToCompany(siteId: $siteId, companyId: $companyId)
  }
`

export const MUTATION_ADMIN_TRANSFER_DEPLOYMENT_TO_SITE = gql`
  mutation AdminTransferDeploymentToSite(
    $deploymentId: String!
    $siteId: String!
  ) {
    transferDeploymentToSite(deploymentId: $deploymentId, siteId: $siteId)
  }
`

export const MUTATION_UPLOAD_BIKEMODEL_PICTURE = gql`
  mutation UploadBikeModelPicture($file: Upload!, $id: String) {
    uploadBikeModelPicture(file: $file, id: $id) {
      avatarUrl
    }
  }
`

export const MUTATION_CREATE_ONE_BIKEMODEL = gql`
  mutation CreateOneBikeModel($data: BikeModelCreateInput!) {
    createOneBikeModel(data: $data) {
      id
    }
  }
`

export const MUTATION_UPDATE_ONE_BIKEMODEL = gql`
  mutation UpdateOneBikeModel(
    $where: BikeModelWhereUniqueInput!
    $data: BikeModelUpdateInput!
  ) {
    updateOneBikeModel(data: $data, where: $where) {
      id
    }
  }
`
export const MUTATION_DELETE_ONE_BIKEMODEL = gql`
  mutation DeleteOneBikeModel($bikeModelId: String!) {
    deleteOneBikeModel(bikeModelId: $bikeModelId) {
      id
    }
  }
`

// export const MUTATION_UPDATE_DEPLOYMENT = gql`
//   mutation UpdateDeployment(
//     $where: DeploymentWhereUniqueInput!
//     $data: DeploymentUpdateInput!
//   ) {
//     updateDeployment(where: $where, data: $data) {
//       id
//     }
//   }
// `

// export const MUTATION_CREATE_DEPLOYMENT_BIKE_MODEL_PRICE = gql`
//   mutation CreateDeploymentBikeModelPrice(
//     $deploymentId: Int!
//     $bikeModelId: Int!
//     $remainingPrice: Float!
//   ) {
//     createDeploymentBikeModelPrice(
//       deploymentId: $deploymentId
//       bikeModelId: $bikeModelId
//       remainingPrice: $remainingPrice
//     ) {
//       id
//     }
//   }
// `

// export const MUTATION_UPDATE_DEPLOYMENT_BIKE_MODEL_PRICE = gql`
//   mutation UpdateDeploymentBikeModelPrice(
//     $id: Int!
//     $remainingPrice: Float!
//   ) {
//     updateDeploymentBikeModelPrice(id: $id, remainingPrice: $remainingPrice) {
//       id
//     }
//   }
// `
// export const MUTATION_DELETE_DEPLOYMENT_BIKE_MODEL_PRICE = gql`
//   mutation DeleteDeploymentBikeModelPrice($id: Int!) {
//     deleteDeploymentBikeModelPrice(id: $id) {
//       id
//     }
//   }
// `
